import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {getRandomWord} from "./query.js";

const Random = () => {
  var history = useHistory();

  useEffect(() => {
    async function fetch() {
      let word = await getRandomWord()
      let path = `/train/${word}`;
      history.push(path);
    }
    fetch();
  }, [history]);

  return(<div/>)
}

export default Random;
