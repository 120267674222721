import React, {useEffect} from "react";
import Train from "./train";
import Random from "./random";
import "./bulma.min.css";

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

const App = () => {
  useEffect(() => {
    document.title = "Worden vervoegen";
  }, []);

  return (
    <Router>
      <div className="App has-navbar-fixed-top">
        <header></header>
        <Switch>
          <Route path="/" exact component={Random} />
          <Route path="/train/:word" component={Train} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
