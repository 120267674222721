import React, {useState} from "react";
import Title from "./title";
import BoxContainer from "./boxcontainer";
import {useHistory} from "react-router-dom";
import {getPossibleWords, getRandomWord} from "./query.js";

const Pick = () => {
  const [input, setInput] = useState("");
  const [words, setWords] = useState([]);
  const [timer, setTimer] = useState(null);
  let history = useHistory();

  function reset() {
    setInput("");
    setWords([]);
    clearTimeout(timer);
    setTimer(null);
  }

  async function r(x) {
    if (x === "") {
      setWords([]);
      return;
    }
    var words = await getPossibleWords(x);
    setWords(words);
  }

  async function handleChange(x) {
    clearTimeout(timer);
    x = x.replace(";", "");
    setInput(x);
    setTimer(setTimeout(async () => await r(x), 500))
  }

  function navToWord(e, word) {
    e.preventDefault();
    reset();
    let path = `/train/${word}`;
    history.push(path);
  }

  async function navToRandom(e) {
    var word = await getRandomWord();
    navToWord(e, word);
  }

  const WordTable = words => {
    return (
      <div>
        {words.map(word => (
          <button
            key={word}
            onClick={e => navToWord(e, word)}
            className="button is-link"
          >
            {word}
          </button>
        ))}
      </div>
    );
  };

  return (
    <BoxContainer>
      <Title title="Nieuwe Woord Kiezen"/>
      <div>
        <button
          onClick={e => navToRandom(e)}
          style={{marginTop: 0.5 + "em", marginBottom: 0.5 + "em"}}
          className="button is-link is-fullwidth"
        >
          Kies willekeurig word
        </button>
        <form onSubmit={e => words.length === 0 ? e.preventDefault() : navToWord(e, words[0])}>
          <label>
            <div className="is-sr-only">
              "Zoek een woord op"
            </div>
            <input
              className="input"
              rows="1"
              type="text"
              placeholder="Zoek een woord op"
              value={input}
              onChange={x => handleChange(x.target.value)}
            />
          </label>
        </form>
      </div>
      <div
        style={{marginTop: "0.5em"}}
        className="is-size-5 buttons has-text-white"
      >
        {WordTable(words)}
      </div>
    </BoxContainer>
  );
};

export default Pick;
