import React, {useState} from "react";
import Title from "./title";
import BoxContainer from "./boxcontainer";

var margintop = {marginTop: "0.5em"};

const HelpContents = () => {
  return (
    <div>
      <h1 style={margintop} className="is-size-4 title">
        Woorden Invoeren
      </h1>
      <p>
        Voer alleen het werkwoord in. Typ je de correcte vervoeging in? Dan
        wordt het balkje groen. Bij het vervoegen van het woord LOPEN typ je bij
        de tegenwoordige tijd van de eerste vorm het volgende in:
      </p>
      <div style={margintop} className="is-sr-only">
        Correcte Vervoeging Voorbeeld
      </div>
      <input
        style={margintop}
        readOnly
        className="is-success input has-background-success has-text-white"
        rows="1"
        type="text"
        value="loop"
      />
      <p style={margintop}>
        Typ je de verkeerde vervoeging in? Dan blijft het balkje wit met een
        rode rand.
      </p>
      <input
        style={margintop}
        readOnly
        className="is-danger input"
        rows="1"
        type="text"
        value="lop"
      />
      <h1 style={margintop} className="is-size-4"></h1>
      <p style={margintop}>Typ alleen het werkwoord in!</p>
      <input
        style={margintop}
        readOnly
        className="is-danger input"
        rows="1"
        type="text"
        value="ik loop"
      />
      <h1 style={margintop} className="is-size-4 title">
        Antwoord Weergeven
      </h1>
      <p>
        Weet je het antwoord niet? Typ dan IK GEEF OP in. Het correcte antwoord
        wordt dan weergegeven.
      </p>
      <p style={margintop}>Typ dit in:</p>
      <input
        style={margintop}
        readOnly
        className="is-danger input"
        rows="1"
        type="text"
        value="ik geef op"
      />
      <p style={margintop}>Dan zie je het antwoord:</p>
      <input
        style={margintop}
        readOnly
        className="is-success input has-background-success has-text-white"
        rows="1"
        type="text"
        value="loop"
      />
    </div>
  );
};

const ShowHelp = props => {
  return (
    <div
      style={margintop}
      className="is-size-5 has-text-centered is-unselectable"
    >
      <button
        onClick={() => props.setShowHelp(!props.showHelp)}
        style={{marginTop: 0.5 + "em", marginBottom: 0.5 + "em"}}
        className="button is-link is-fullwidth"
      >
        Hoe werkt het?
      </button>
    </div>
  );
};

const Help = () => {
  const [showHelp, setShowHelp] = useState(false);
  return (
    <BoxContainer>
      <Title title="Hulp" />
      <ShowHelp showHelp={showHelp} setShowHelp={setShowHelp} />
      {showHelp ? <HelpContents /> : null}
    </BoxContainer>
  );
};

export default Help;
