import React, {useState, useEffect} from "react";
import Pick from "./pick";
import Title from "./title";
import Help from "./help";
import BoxContainer from "./boxcontainer";
import {getAllConjugations} from "./query.js";

const Train = props => {
  const word = props.match.params.word;
  const [data, setData] = useState([]);
  const [wordFound, setWordFound] = useState(true);
  const [answers, setAnswers] = useState(["", "", "", "", "", "", "", "", ""]);
  const [correct, setCorrect] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [score, setScore] = useState(0);

  function reset() {
    setData([]);
    setWordFound(true);
    setAnswers(["", "", "", "", "", "", "", "", ""]);
    setCorrect([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setScore(0);
  }

  useEffect(() => {
    async function fetch() {
      reset();
      let newData = await getAllConjugations(word);
      if (!newData || newData.length === 0) {
        setWordFound(false);
      } else {
        setWordFound(true);
      }
      setData(newData);
      document.title = `${word} vervoegen`;
      window.scrollTo(0, 0);
    }

    fetch();
  }, [word]);

  function checkAnswers(newAnswers) {
    var newCorrect = correct.slice();
    for (var i = 0; i < answers.length; i++) {
      var possibleAnswers = data[i]
        .toLowerCase()
        .split("/")
        .flatMap(x => x.split(","))
        .filter(x => x.length > 0);
      if (
        possibleAnswers.some(
          ans => ans.trim() === newAnswers[i].toLowerCase()
        ) ||
        data[i] === newAnswers[i].toLowerCase()
      ) {
        newCorrect[i] = 1;
      } else {
        newCorrect[i] = 0;
      }
    }
    setCorrect(newCorrect);
    var newScore = newCorrect.reduce((a, b) => a + b);
    setScore(newScore);
  }

  function update(x, i) {
    var newAnswers = answers.slice();

    if (x.toLowerCase().trim() === "ik geef op") {
      newAnswers[i] = data[i];
    } else {
      newAnswers[i] = x;
    }

    setAnswers(newAnswers);
    checkAnswers(newAnswers);
  }

  function showAnswers() {
    setAnswers(data);
    checkAnswers(data);
  }

  const WordInput = (index, placeholder) => {
    return (
      <div style={{marginTop: 0.5 + "em", marginBottom: 0.5 + "em"}}>
        <label>
          <div className="is-sr-only">{placeholder}</div>
          <input
            className={
              correct[index] === 1
                ? "is-success input has-background-success has-text-white"
                : "is-danger input"
            }
            rows="1"
            type="text"
            value={answers[index]}
            placeholder={placeholder}
            onChange={x => update(x.target.value, index)}
          />
        </label>
      </div>
    );
  };

  return (
    <div>
      <section style={{display: "block"}} className="navbar is-fixed-top">
        <div
          style={{padding: "1em"}}
          className="has-background-black has-text-white is-radiusless"
        >
          <div className="container has-text-centered is-size-1-desktop is-size-3-touch is-size-6-tablet">
            {word.toUpperCase()}
            {wordFound ? null : (
              <div>Het word is niet gevonden. Kies een andere woord.</div>
            )}
          </div>
        </div>
      </section>

      <section
        className={wordFound ? "section" : "section is-hidden"}
        style={{marginTop: 4.5 + "em"}}
      >
        <div className="container">
          <div className="columns is-desktop">
            <div className="column">
              <BoxContainer>
                <Title title="Tegenwoordige tijd" />
                {WordInput(0, "Ik")}
                {WordInput(1, "Jij/Je")}
                {WordInput(2, "Hij/Zij/Het")}
                {WordInput(3, "Wij/Jullie/Zij")}
                <Title title="Verleden tijd" />
                {WordInput(4, "Ik")}
                {WordInput(5, "Jij/Je")}
                {WordInput(6, "Hij/Zij/Het")}
                {WordInput(7, "Wij/Jullie/Zij")}
                <Title title="Voltooid Deelwoord" />
                {WordInput(8, "Ik ben/heb")}
                <div className="has-text-centered">
                  Je score: {score} / {answers.length}
                </div>
              </BoxContainer>
            </div>
            <div className="column">
              <Pick />
              <Help />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Train;
