import React from "react";

const Title = props => {
  return (
    <div
      style={{padding: "0.5em"}}
      className="has-text-centered has-text-weight-semibold has-background-black has-text-white is-size-3 has-text-centered is-marginless"
    >
      {props.title}
    </div>
  );
};

export default Title;
