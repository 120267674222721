const address = "https://taal.fstelmach.nl/api";

// AANNEMEN FAILS HERE
export async function getPossibleWords(word) {
  const q = `${address}/conjugation?limit=100&conjugation=ilike.*${word}*`;
  // const q2 = `http://${address}/simple_conjugation?conjugation=like.${word}`;
  const q2 = `${address}/simple_conjugation?limit=100&or=(conjugation.ilike.*${word}*,word.like.*${word}*)`;

  var res = await fetch(q);
  res = await res.json();

  var res2 = await fetch(q2);
  res2 = await res2.json();

  var words = res.concat(res2);

  return uniqueWords(words);
}

function uniqueWords(words) {
  var set = new Set();
  for (var i = 0; i < words.length; i++) {
    var str = words[i].word;
    set.add(str);
  }
  return [...set].sort((a, b) => {
    var d = a.length - b.length;
    if (d !== 0) {
      return d;
    }
    return a.localeCompare(b);
  });
}

export async function getAllConjugations(word) {
  const conjugationQuery = `${address}/conjugation?word=eq.${word}`;
  const simpleConjugationQuery = `${address}/simple_conjugation?word=eq.${word}`;

  let [res, res2] = await Promise.all([
    fetch(conjugationQuery),
    fetch(simpleConjugationQuery)
  ]);

  [res, res2] = await Promise.all([res.json(), res2.json()]);

  return parseConjugations(res, res2);
}

function parseConjugations(conjs, simpleConjs) {
  if (conjs === null || conjs.length === 0) {
    return;
  }

  conjs.sort((a, b) => {
    var d = a.type.localeCompare(b.type);
    if (d === 0) {
      return a.form.localeCompare(b.form);
    }
    return d;
  });

  var x = [];
  var vals = [2, 3, 1, 4, 10, 11, 9, 12];

  for (var i = 0; i < vals.length; i++) {
    x.push(conjs[vals[i]].conjugation);
  }

  simpleConjs.sort((a, b) => {
    return a.type.localeCompare(b.type);
  });

  x.push(simpleConjs[2].conjugation);

  return x;
}

const words = {words: [], irregularWords: []};

async function downloadWords() {
  if (words.words.length !== 0) {
    return;
  }

  const randomWordQuery = `${address}/word`;
  const randomIrregularWordQuery = `${address}/irregular_word`;

  let [res, res2] = await Promise.all([
    fetch(randomWordQuery),
    fetch(randomIrregularWordQuery)
  ]);

  [res, res2] = await Promise.all([res.json(), res2.json()]);

  words.words = res;
  words.irregularWords = res2;
}

function pickRandom(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export async function getRandomWord() {
  await downloadWords();
  let r = Math.random();
  let word = r < 0.419 ? pickRandom(words.irregularWords) : pickRandom(words.words);
  return word.word;
}
